import { graphql, PageProps } from "gatsby";
import React from "react";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { FreeBlock01 } from "../../../components/FreeBlock01";
import { Heading01 } from "../../../components/Heading01";
import { Layout } from "../../../components/Layout";
import bgimgPc from "../../../images/h1/h1flow.png";
import bgimgSp from "../../../images/h1/h1flow_sp.png";

const FlowPage: React.VFC<PageProps<GatsbyTypes.FlowPageQuery>> = ({
  data,
}) => {
  const blocks = data.wpPage?.casM;

  return (
    <Layout title="製品一覧" description="ミスギの製品一覧ページです。">
      <Heading01
        label="FLOW"
        smallLabel="施工方法（キャスコーナーM級）"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <div className="l-container">
        <Breadcrumb
          labels={[
            { url: "/products", text: "製品一覧" },
            { url: "/products/product120", text: "キャスコーナーM級" },
            { url: "", text: "施工方法" },
          ]}
        />
      </div>
      <div className="l-container-sm py-8 pc:py-14">
        <p className="text-base font-bold pc:text-xl">
          キャスコーナーの取り付け要領
          <br />
          <br />
          ボルト一本で連結 工具はスパナ2本の簡単施工
        </p>
        {blocks &&
          Object.values(blocks).map(
            (block, index) =>
              block?.desc && (
                <FreeBlock01
                  key={index}
                  heading={`STEP 0${index + 1}`}
                  text={block?.desc}
                  img={block?.img1}
                  img2={block?.img2}
                />
              )
          )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FlowPage {
    wpPage(title: { eq: "施工方法（キャスコーナーM級）" }) {
      casM {
        freeblock01 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        freeblock02 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        freeblock03 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        freeblock04 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        freeblock05 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        freeblock06 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        freeblock07 {
          desc
          img1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          img2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default FlowPage;
