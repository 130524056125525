import React from "react";

import { Props } from "../../types/FreeBlock01";
import { Heading04 } from "../Heading04";
import { WpImage } from "../WpImage";

/**
 * 見出しはh2をメインに想定
 */
export const Presenter: React.VFC<Props> = ({
  heading,
  text,
  img,
  img2,
  HeadTagName = "h2",
}) => {
  if (!heading && !text && !img && !img2) {
    return null;
  }
  return (
    <div className="my-10 overflow-hidden">
      {heading && <Heading04 label={heading} TagName={HeadTagName} />}
      {text && (
        <p
          className="my-8 text-base"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
      )}
      <div className="flex justify-center">
        {img && (
          <div>
            <WpImage image={img} />
          </div>
        )}
        {img2 && (
          <div className="ml-4 pc:ml-8">
            <WpImage image={img2} />
          </div>
        )}
      </div>
    </div>
  );
};
